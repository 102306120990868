<template>
  <NotificationBase :notification :compact :icon="{ name: 'file-check-alt' }">
    <template #subject>
      {{ content.title }}
    </template>
    <p>
      {{ content.message }}
    </p>
  </NotificationBase>
</template>

<script setup lang="ts">
import { groupBy } from '@st/utils'
import NotificationBase from '../NotificationBase.vue'
import type { CarrotNotification } from '../../../../types'

interface Props {
  notification: CarrotNotification
  compact?: boolean
}

const { notification, compact = false } = defineProps<Props>()

const { locale } = useI18n()

const content = computed(() => {
  const groupedData = groupBy(notification.data.data, (item) => item.lang)

  return (
    groupedData.get(locale.value)[0] ??
    groupedData.get('en')[0] ??
    [...groupedData.values()][0]
  )
})
</script>
